import React, {Component} from 'react'
import {YMaps, Placemark, Map, Clusterer} from 'react-yandex-maps'
import {hot} from 'react-hot-loader'

const formatCounter = (number) => {
    const titles =  ['магазин', 'магазина', 'магазинов']
    const cases = [2, 0, 1, 1, 1, 2]  
    return titles[ (number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5] ]  
}

class App extends Component {
    constructor(props) {
        super(props)

        const sp = (new URL(window.location.href)).searchParams

        this.state = {
            mapState: {
                center: [sp.get('lat') || 55.751574, sp.get('lng') || 37.573856],
                zoom: sp.get('zoom') || 10,
                controls: ['zoomControl', 'geolocationControl'],
            },
            template: null,
            ymaps: null,
            items: [],
            cluster: null,
            query: '',
        }
    }

    createTemplateLayoutFactory = ymaps => {
        if (ymaps && !this.state.template) {
            this.setState({
                layout: ymaps.templateLayoutFactory.createClass('<div class="store-window">' +
                '<div class="store-window__close"></div>' +
                '<div class="store-window-content">' +
                '$[[options.contentLayout observeSize minWidth=300 maxWidth=334]]' +
                '</div></div>', {

                    build: function () {
                        this.constructor.superclass.build.call(this)

                        this._$element = this.getParentElement().querySelector('.store-window')
                        this.applyElementOffset()

                        this._$element.querySelector('.store-window__close').addEventListener('click', () => {
                            this.events.fire('userclose')
                        }, false)
                    },
                    applyElementOffset: function () {
                        this._$element.style.left = '30px'
                        this._$element.style.bottom = `-${this._$element.offsetHeight * 0.3}px`
                        this._$element.style.display = 'block'
                    },
                    getShape: function () {
                        if (this._$element === null) {
                            return this.superclass.getShape.call(this)
                        }
                        return new ymaps.shape.Rectangle(new ymaps.geometry.pixel.Rectangle([
                            [this._$element.offsetLeft, this._$element.offsetTop - 100], [
                                this._$element.offsetLeft + this._$element.offsetWidth,
                                this._$element.offsetTop + this._$element.offsetHeight
                            ]
                        ]))
                    },
                }),
                template: ymaps.templateLayoutFactory.createClass(
                    '$[properties.balloonContent]'
                ),
            })
        }
    };

    componentWillMount() {
        fetch('https://c.pntr.io/api/spar-export')
            .then(resp => resp.json())
            .then(items => {
                this.setState({items})
            })
            .catch(e => {
                console.log(e) // eslint-disable-line
            })
    }

    handleSearch = (e) => {
        this.setState({
            query: e.target.value,
        })
    }

    formatWorkingHours = (str) => {
        if (!str) {
            return ''
        }
        let items = str.split(', ')
        const itms = items.map(i => i.split(': ')[1])
        const first = itms[0]
        for (const iter in itms) {
            if (itms[iter] != first) {
                return items.join('<br/>')
            }
        }
        
        const [from, to] = first.split('-')
        if (from == '00:00' && to == '24:00') {
            return 'круглосуточно'
        }

        return `ежедневно, с ${parseInt(from.split(':'))}:${from.split(':')[1]} до ${to}`
    }

    formatPhone = (phone) => {
        // convert 8 (800) 500-72-04 to +78005007204 
        return `+7${phone.replace(/\D/g, '').slice(1)}`
    }

    createRouteLink= (item) => {
        if (this.isSparApp()) {
            return `sparonline://navigator/?lat=${item.geo.split(',').join(';long=')}`
        }
        return `https://maps.yandex.ru/?rtext=~${item.geo}`
    }
        
    createReviewDeepLink = (item) => {
        return `sparonline://shop/${item.uuid}`
    }

    isMobile = () => {
        return navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)
    }

    isSparApp = () => {
        return navigator.userAgent.match('Agent-SparOnline-Mobile-App')
    }

    render() {
        const {items, mapState, ymaps, query, cluster, displayFilter} = this.state

        const queryLower = query.toLowerCase()
        
        const filteredItems = items.filter(i => {
            return i.geo && ((i.title || '').toLowerCase().match(queryLower) || (i.city || '').toLowerCase().match(queryLower) || (i.address || '').toLowerCase().match(queryLower))
        })
        
        const geoObjects = filteredItems.map(item => {
            return <Placemark
                key={item.uuid}
                geometry={{
                    type: 'Point',
                    coordinates: item.geo.split(',').map(g => Number(g)),
                }}
                options={{
                    iconLayout: 'default#imageWithContent',
                    iconImageHref: require('./assets/img/spar-map-icon.png'),
                    iconImageSize: [46, 60],
                    iconImageOffset: [-23, -30],
                    balloonLayout: this.state.layout,
                    balloonContentLayout: this.state.template,
                }}
                properties={{
                    uuid: item.uuid,
                    balloonContent: `
                      ${item.photo ? `<img class="store-window__image" src="${item.photo}">` : ''}
                      <div class="store-info">
                        <div class="store-name">
                          <div class="store-name__name">${item.title}</div>
                          <div class="store-name__hours">${this.formatWorkingHours(item.working_hours, item)}</div>
                        </div>
                        <div class="store-rating store-rating-${Math.floor(item.rating)} ${item.rating % 1 >= 0.5 ? 'store-rating-half' : ''}">
                          <div class="store-rating__star"></div>
                          <div class="store-rating__star"></div>
                          <div class="store-rating__star"></div>
                          <div class="store-rating__star"></div>
                          <div class="store-rating__star"></div>
                        </div>
                        <div class="store-address">${item.city}, ${item.address}</div><a class="store-phone" href="tel:${this.formatPhone(item.phone)}">${item.phone}</a><a class="store-route" ${!this.isSparApp() ? 'target="_blank"' : ''} href="${this.createRouteLink(item)}">Маршрут</a>
                        ${this.isSparApp() ? `<div class="store-leave-feedback"><a class="store-leave-feedback__link" href="${this.createReviewDeepLink(item)}">Оставить отзыв</a></div>` : ''}
                      </div>`,
                }}
            />
        })

        return (
            <div className="map-block-container">
                <div className="map-block">
                    <div className="map-search-field-container">
                        <form className="map-search-form" onSubmit={e => e.preventDefault()}>
                            <input className="map-search-form__input" autoComplete="off" type="text" id="searchField" placeholder="Поиск по адресу" onChange={this.handleSearch} onClick={() => !displayFilter && this.setState({displayFilter: true})} value={query}/>
                            <button className="map-search-form__submit"></button>
                            <button className="map-search-form__clear" type="reset" onClick={() => {
                                this.setState({query: '', displayFilter: false})
                            }}></button>
                        </form>
                        {cluster && displayFilter && filteredItems.length > 0 && (
                            <div className="search-results">
                                <div className="search-results__info">Введите город или адрес<span>Найдено: {filteredItems.length} {formatCounter(filteredItems.length)}</span></div>
                                <div className="search-results__list">
                                    {filteredItems.map(f => (
                                        <div key={f.uuid} className="search-result-card" onClick={() => {
                                            this.map.setCenter(f.geo.split(',')
                                                .map(g => Number(g)), 16)
                                                .then(() => {
                                                
                                                    const selectedObject = cluster.getGeoObjects().find(o => {
                                                        return o.properties._data.uuid == f.uuid
                                                    })
                                                
                                                    if (selectedObject) {
                                                        const geoObjectState = cluster.getObjectState(selectedObject)
                                                        // Проверяем, находится ли объект в видимой области карты.
                                                        if (geoObjectState.isShown) {
                                                            // Если объект попадает в кластер, открываем балун кластера с нужным выбранным объектом.
                                                            if (!geoObjectState.isClustered) {
                                                                // Если объект не попал в кластер, открываем его собственный балун.
                                                                selectedObject.balloon.open().then(() => {                                           
                                                                    if (this.isMobile() || this.isSparApp()) {
                                                                        this.setState({displayFilter: false})
                                                                    }
                                                                })  
                                                            }
                                                        }
                                                    }
                                                })
                                                .catch(e => console.log(e)) // eslint-disable-line no-console
                                        }}>
                                            <div className="search-result-card__name">{f.title}</div>
                                            <div className="search-result-card__rating">
                                                <div className={`store-rating store-rating-${Math.floor(f.rating)} ${f.rating % 1 >= 0.5 ? 'store-rating-half' : ''}`}>
                                                    <div className="store-rating__star"></div>
                                                    <div className="store-rating__star"></div>
                                                    <div className="store-rating__star"></div>
                                                    <div className="store-rating__star"></div>
                                                    <div className="store-rating__star"></div>
                                                </div>
                                                <div className="rating-value">{f.rating || ''}</div>
                                            </div>
                                            <div className="search-results-card__address">{f.city}, {f.address}</div>
                                            <div className="search-results-card__hours">{this.formatWorkingHours(f.working_hours)}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <YMaps query={{lang: 'ru_RU', load: 'package.full'}}>
                        <Map className="spar-map"
                            onLoad={ymaps => {
                                this.createTemplateLayoutFactory(ymaps)
                                this.setState({ymaps})
                            }}
                            onClick={() => displayFilter && this.setState({displayFilter: false})}
                            instanceRef={ref => this.map = ref}
                            defaultState={mapState}
                            modules={['templateLayoutFactory', 'layout.ImageWithContent', 'control.ZoomControl', 'control.FullscreenControl']}
                            width="100%"
                            height="100%">
                            {ymaps && (
                                <Clusterer
                                    instanceRef={ref => {
                                        if (ref && !cluster) {
                                            this.setState({cluster: ref})
                                        }
                                    }}
                                    options={{
                                        groupByCoordinates: false,
                                        clusterIcons: [
                                            {
                                                href: require('./assets/img/spar-cluster-small-icon.png'),
                                                size: [40, 40],
                                                offset: [-20, -20]
                                            },
                                            {
                                                href: require('./assets/img/spar-cluster-big-icon.png'),
                                                size: [60, 60],
                                                offset: [-30, -30]
                                            }
                                        ],
                                        clusterDisableClickZoom: false,
                                        clusterHideIconOnBalloonOpen: false,
                                        geoObjectHideIconOnBalloonOpen: false,
                                        clusterNumbers: [10],
                                        clusterIconContentLayout: ymaps.templateLayoutFactory.createClass('<div style="font-family: rotonda, sans-serif; font-size: 18px; color: #FFFFFF; font-weight: 400; margin-top:-8%;">{{ properties.geoObjects.length }}</div>')
                                    }}
                                >
                                    {geoObjects}
                                </Clusterer>
                            )}
                        </Map>
                    </YMaps>
                </div>
            </div>
        )
    }
}

export default hot(module)(App)
